import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ["row", "sidebar"];

    connect() {
        console.log('highlighted')
    }
    highlightRow(event) {
        // Remove the 'bg-gray-100' class from all rows
        this.rowTargets.forEach((row) => {
            row.classList.remove("bg-gray-100", "text-blue-600", "ring-blue-600");
        });

        // Add the 'bg-gray-100' class to the clicked row
        const clickedRow = event.currentTarget;
        clickedRow.classList.add("bg-gray-100", "ring-blue-600", "ring-2");

        // Get the customer ID from the clicked row's data attributes
        const customerId = clickedRow.dataset.customerId;

        // Open the sidebar with the customer ID
        this.openSidebar(customerId);
    }

    openSidebar(customerId) {

        // Show the sidebar
        this.sidebarTarget.classList.remove("hidden");
        this.sidebarTarget.classList.add("block");
    }
}
